<template>
    <div class="member-chart">
        <div id="chartBox" v-show="yConsumeList && yConsumeList.length"></div>
        <p class="no-data" v-show="!yConsumeList">暂无数据</p>
    </div>
</template>
<script>
	export default {
		name:"analysis-chart",
		props:{
			chartData:Object,
			timeParams:Object,
		},
		data(){
			return{
				xList:null,
				yConsumeList:null,
				yGiftList:null,
			}
		},
		watch:{
			timeParams:{
				handler(newVal,oldVal){
					if(! newVal){
						return
					}
					if(! ! oldVal && newVal.startTime === oldVal.startTime){
						return
					}
					this.resolveChartData()
				},
				deep:true,
				immediate:true,
			},
			chartData:{
				handler(){
					if(!this.chartData){
						return
                    }
					this.resolveChartData()
				},
				deep:true,
				immediate:true,
			},
		},
		methods:{
			/**
			 * 处理图表数据
			 */
			resolveChartData(){
				let {chartData} = this
				//实际会员充值
				if(chartData&&chartData.member_consume_list){
					let xList = []
					let yConsumeList = []
					let yConsumeMaxDigit = 0;
					let yConsumeDigit = 0
					for(let item of chartData.member_consume_list) {
						for(let key in item){
							yConsumeDigit = parseInt(item[key],10).length
							yConsumeMaxDigit = yConsumeMaxDigit > yConsumeDigit ? yConsumeMaxDigit : yConsumeDigit
							xList.push(key)
							yConsumeList.push(item[key])
						}
					}
					yConsumeList.forEach(value => value = value/Math.pow(10,yConsumeDigit))
					this.xList = xList
					this.yConsumeList = yConsumeList
				}

				//会员卡赠送
				if(chartData&&chartData.consume_gift_list){
					let yGiftList = []
					let yGiftMaxDigit = 0;
					let yGiftDigit = 0
					for(let item of chartData.consume_gift_list) {
						for(let key in item){
							yGiftDigit = parseInt(item[key],10).length
							yGiftMaxDigit = yGiftMaxDigit > yGiftDigit ? yGiftMaxDigit : yGiftDigit
							yGiftList.push(item[key])
						}
					}
					yGiftList.forEach(value => value = value/Math.pow(10,yGiftDigit))
					this.yGiftList = yGiftList
				}
				this.renderChart()
			},
			/**
			 * 配置营业分析走势图
			 */
			renderChart(){
				let chart = this.$echarts.init(document.getElementById('chartBox'))
				let {yConsumeList,yGiftList,xList} = this
				var color = '#0066ff'
				let tooltipBack = 'rgba(50,50,50,0.6)'
				let option = {
					theme:'light',
					grid:{
						left:'5%',
						right:'6%',
						bottom:'3%',
						top:'10%',
						containLabel:true
					},
					xAxis:{
						type:'category',
						boundaryGap:false,
						data:xList,
						axisTick:{
							alignWithLabel:true
						},
						axisPointer:{
							snap:true,
						},
						axisLine:{
							show:false
						},
						axisTick:{
							show:false,
						},
					},
					yAxis:{
						type:'value',
						splitNumber:5,
						axisLine:{
							lineStyle:{
								color:'#dfdfdf'
							}
						},
						axisTick:{
							show:false,
						},
						axisLabel:{
							inside:true,
							color:'#333333'
						},
					},
					tooltip: {
						trigger: 'axis',
            textStyle: {
               color: "#FFFFFF" //设置文字颜色
            },
						backgroundColor:tooltipBack,
						confine: true,
						formatter:function(params){
							let {axisValue} = params[0]
							let time = axisValue.split('.')
							let dateTime = time[0]+'月'+time[1]+'日'
							let relVal = ''
							for (var i = 0; i < params.length; i++) {
								relVal +=
										// '<div><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background:'+params[i].color+'"></span>'+
										// params[i].seriesName + ' : ' + parseFloat(params[i].value).toLocaleString('en-US')+"元</div>";
										'<div><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background:'+params[i].color+'"></span>'+
										params[i].seriesName + ' : ' + params[i].value +"元</div>";
							}
							return "日期："+dateTime+"<br/>"+relVal;
						}
					},
					series:[
						{
							name:'实际会员消费',
							data:yConsumeList,
							type:'line',
							smooth:true,
							itemStyle:{
								normal:{
									color:'#397eff',
									lineStyle:{
										color
									},
									areaStyle:{
										color:"#d4f0ff",
									}
								},
							},

						},{
							name:'会员卡赠送消费',
							data:yGiftList,
							type:'line',
							color:'#ff9500',
							smooth:true,
							itemStyle:{
								normal:{
									color:'#ff9500',
									lineStyle:{
										color:'#ff9500'
									},
									areaStyle:{
										color:"#f6dbb1",
									}
								},
							},
						}
					]
				};
				chart.setOption(option)
			}
		},
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";
    .member-chart{
        background:#FFFFFF;
    }
    #chartBox{
        height:460px;
        width:100vw;
    }
    .no-data{
        background:#FFFFFF;
        display:flex;
        align-items:center;
        justify-content:center;
        color:#666666;
        height:500px;
    }
</style>